/*eslint-disable no-unused-vars */
/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, InputGroup, Label, Row, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { t } from 'i18next';

import { CompanyId, ITEMS_PER_PAGE, JobRole, USER_ROLE } from '../../constants';
import LocalstorageService from '../../helpers/localstorage-service';
import MachineService from '../../services/machines';

import { setLoadingOverlay } from '../../store/slices/loadingOverlaySlice';

import SortableHeader from '../../components/SortableHeader';
import NoRecords from '../../components/Table/NoRecords';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import ActionBtns from '../../components/ActionBtns';

const HeaderList = [
  {
    title: t('MACHINE.SERIAL_NAME_LABEL'),
    value: 'serialNo',
    style: { minWidth: '150px', width: '30%' },
  },
  {
    title: t('LOCATION.TITLE'),
    value: 'locations',
    style: { minWidth: '150px', width: '30%' },
  },
];

const reqPayload = { pageNo: 1 };

function MachinesList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [setPageTitle, setBreadcrumbItems] = useOutletContext();

  const [roleID, setRoleID] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  const [deleteItem, setDeleteItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [showDeleteTerminalModal, setShowDeleteTerminalModal] = useState(false)

  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState('');
  const [showDelRec, setShowDelRec] = useState(false);

  useEffect(() => {
    setPageTitle(t('MACHINE.TITLE'));
    setBreadcrumbItems([
      { title: t('APPLICATION_TITLE'), link: '#' },
      { title: t('MACHINE.TITLE'), link: '#' },
    ]);

    const companyId = LocalstorageService.getItem(CompanyId);
    reqPayload['companyId'] = companyId;
    reqPayload['showDelRec'] = showDelRec;

    const jobRole = LocalstorageService.getItem(JobRole);
    setRoleID(jobRole);
  }, []);

  useEffect(() => {
    delete reqPayload['search'];
    if (search) {
      reqPayload['search'] = search;
    }
    reqPayload['pageNo'] = 1;
    setCurrentPage(1);
    listFn();
  }, [search]);

  async function listFn() {
    setIsLoading(true);
    dispatch(setLoadingOverlay(true));
    await MachineService.listMachines(reqPayload)
      .then((response) => {
        const { status, message, dataList, totalCount } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        if (list.length % ITEMS_PER_PAGE === 1 && reqPayload['pageNo'] !== 1) reqPayload['pageNo'] -= 1;

        setList(dataList || []);
        setTotalCount(totalCount);
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setLoadingOverlay(false));
      });
  }

  const deleteModal = (item) => {
    setDeleteItem(item);
    setShowDeleteModal(true);
  };

  const resetModal = () => {
    setDeleteItem(null);
    setShowDeleteModal(false);
    setShowActivateModal(false);
  };

  const handleCancleTerminalDelete = async () => {
    const deleteLocation = false
    setIsLoading(true);
    await MachineService.deleteMachine({
      terminalNumber: deleteItem.terminalNumber,
      locationId: deleteItem.locationId,
      deleteLocation
    })
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        listFn();
      })
      .finally(() => {
        setIsLoading(false);
        setShowDeleteTerminalModal(false)
      });

  }

  const confirmDelete = async () => {
    const deleteLocation = true
    setIsLoading(true);
    await MachineService.deleteMachine({
      terminalNumber: deleteItem.terminalNumber,
      locationId: deleteItem.locationId,
      deleteLocation
    })
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        listFn();
      })
      .finally(() => {
        setIsLoading(false);
        setShowDeleteTerminalModal(false)
      });

    resetModal();
  };

  const editFn = (item) => {
    navigate(`../update/${item.terminalNumber}`, {
      state: { companyId: item.companyId },
    });
  };

  const sortBy = (sortObj) => {
    delete reqPayload['sort'];
    if (sortObj) {
      reqPayload['sort'] = [sortObj];
    }
    listFn();
  };

  const debouncedChangeHandler = useCallback(debounce(updateSearch, 500), []);

  function updateSearch(e) {
    setSearch(e.target.value?.trim());
  }

  const handlePageClick = (currentPage) => {
    reqPayload['pageNo'] = currentPage;
    setCurrentPage(currentPage);
    listFn();
  };

  const handleShowDeleteRec = () => {
    delete reqPayload['search'];
    reqPayload['showDelRec'] = !showDelRec;
    setShowDelRec(!showDelRec);
    reqPayload['pageNo'] = 1;
    setCurrentPage(1);
    listFn();
  };

  const confirmActivate = async () => {
    dispatch(setLoadingOverlay(true));
    await MachineService.activateTerminal({
      terminalNumber: deleteItem.terminalNumber,
      companyId: deleteItem.companyId,
    })
      .then((response) => {
        const { status, message } = response.data;

        if (status === 400) {
          toast.error(message);
          return;
        }

        toast.success(message);
        listFn();
      })
      .finally(() => {
        dispatch(setLoadingOverlay(false));
      });

    resetModal();
  };

  return (
    <React.Fragment>
      <div className=" align-items-end mb-2">
        <Row>
          <Col xs={7} sm={4}>
            <Form className="search-filter" onSubmit={(e) => e.preventDefault()}>
              <FormGroup className="">
                <InputGroup>
                  <Input onChange={debouncedChangeHandler} placeholder={t('SEARCH')} />
                  <Button color="primary" type="button">
                    <i className="ri-search-line"></i>
                  </Button>
                </InputGroup>
              </FormGroup>
            </Form>
          </Col>

          <Col xs={6} sm={4} className="d-none d-sm-block">
            {(roleID === USER_ROLE.SUPER_ADMIN || roleID === USER_ROLE.COMPANY_ADMIN) && (
              <FormGroup className="float-end pt-2 " check>
                <Input
                  id="showDeleteRec"
                  title={t('SHOW_DEL_REC')}
                  type="checkbox"
                  onChange={handleShowDeleteRec}
                  checked={showDelRec}
                />

                <Label htmlFor="showDeleteRec" className="mb-0 d-sm-block">
                  {t('SHOW_DEL_REC')}
                </Label>
              </FormGroup>
            )}
          </Col>

          <Col xs={5} sm={4}>
            <Button color="primary" className="float-end " onClick={() => navigate('../add')}>
              <span className="d-none d-sm-block">{t('MACHINE.ADD')}</span>
              <i className="ri-add-line d-block d-sm-none" title={t('MACHINE.ADD')}></i>
            </Button>
          </Col>
        </Row>
        <Row className="d-sm-none">
          <Col xs={8} sm={6} className="mt-2">
            {(roleID === USER_ROLE.SUPER_ADMIN || roleID === USER_ROLE.COMPANY_ADMIN) && (
              <FormGroup check>
                <Input
                  id="showDeleteRec"
                  title={t('SHOW_DEL_REC')}
                  type="checkbox"
                  onChange={handleShowDeleteRec}
                  checked={showDelRec}
                />

                <Label htmlFor="showDeleteRec" className="mb-0  d-sm-block">
                  {t('SHOW_DEL_REC')}
                </Label>
              </FormGroup>
            )}
          </Col>
        </Row>
      </div>

      <Table striped bordered responsive size="sm">
        <thead>
          <tr>
            <SortableHeader headers={HeaderList} isDisabled={list.length === 0} sortClick={sortBy} />
          </tr>
        </thead>

        <tbody>
          {list.map((item, l0Idx) => (
            <tr key={l0Idx}>
              <td className="actionCol">
                <ActionBtns
                  {...(showDelRec
                    ? {
                        activateFn: () => {
                          setDeleteItem(item);
                          setShowActivateModal(true);
                        },
                      }
                    : {
                        deleteTitle: t('MACHINE.DELETE'),
                        deleteFn: () => deleteModal(item),
                        updateTitle: t('MACHINE.UPDATE'),
                        updateFn: () => editFn(item),
                      })}
                />
              </td>

              <td>{item.terminalNumber}</td>
              <td>{item.locationName}</td>
            </tr>
          ))}

          {!isLoading && list.length === 0 && <NoRecords colSpan={HeaderList.length + 1} />}
        </tbody>
      </Table>

      <div className="custom-pagination mt-2">
        <PaginationControl
          page={currentPage}
          total={totalCount}
          limit={ITEMS_PER_PAGE}
          changePage={handlePageClick}
          next={true}
          last={true}
          ellipsis={1}
        />
      </div>

      <ConfirmDeleteModal
        isOpen={showActivateModal}
        isLoading={isLoading}
        message={t('ALERT.ACT_TERMINAL')}
        modalSize=""
        confirmFn={confirmActivate}
        cancelFn={resetModal}
        btnName={t('ACTIVATE')}
      />

      <ConfirmDeleteModal
        isOpen={showDeleteModal}
        isLoading={isLoading}
        message={t('MACHINE.TERMINAL_DELETE_MODAL_MESSAGE')}
        confirmFn={() => {
          setShowDeleteTerminalModal(true)
          setShowDeleteModal(false)
        }}
        cancelFn={resetModal}
      />

      <ConfirmDeleteModal
        isOpen={showDeleteTerminalModal}
        message={t('MACHINE.TERMINAL_LOCATION_DELETE_MODAL_MESSAGE')}
        isLoading={isLoading}
        confirmFn={confirmDelete}
        cancelFn={handleCancleTerminalDelete}
      />
    </React.Fragment>
  );
}

export default MachinesList;
